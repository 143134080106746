import Vue from 'vue'
import App from './App.vue'


// Font awsome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog, faShoppingCart, faKey, faMapMarker ,faSearch , faSortAmountDown, faWineGlass,faPlus,faMinus,faTrash,faEdit,faCogs,faUser,faPlusSquare,faHome,faStoreAlt,faUmbrellaBeach,faDoorClosed} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faShoppingCart,faCog,faKey, faMapMarker,faSearch,faSortAmountDown,faWineGlass,faPlus,faMinus,faTrash,faEdit,faCogs,faUser,faPlusSquare,faHome,faStoreAlt,faUmbrellaBeach,faDoorClosed)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


import Notifications from 'vue-notification'
Vue.use(Notifications)


Vue.config.productionTip = false

import router from './router'

import VueHtmlToPaper from 'vue-html-to-paper';

import UUID from "vue-uuid";
 
Vue.use(UUID);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
